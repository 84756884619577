import React from "react";
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import IconArrowDown from "../../images/icon-arrow-down.png";

const LanguageSwitcher = () => {
  return (
    <>
      <div className="dropdown language-switcher-dropdown">
        <button
          className="btn btn-language dropdown-toggle"
          type="button"
          id="languageSwitcher"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <span className="d-md-none">PT</span>
          <span className="d-none d-md-inline-block">Portuguese</span>

          {/* <StaticImage
            className="ms-1 icon-arrow-down"
            src="../../images/icon-arrow-down.png"
            alt="Icon Arrow Down"
            quality={100}
          /> */}
          <img
            className="ms-1 icon-arrow-down"
            src={IconArrowDown}
            alt="Icon Arrow Down"
          ></img>
        </button>
        <ul className="dropdown-menu" aria-labelledby="languageSwitcher">
          <li>
            <a className="dropdown-item d-md-none" href="/">
              EN
            </a>
            <a className="dropdown-item d-none d-md-inline-block" href="/">
              English
            </a>
          </li>
          <li>
            <a className="dropdown-item d-md-none" href="/es">
              ES
            </a>
            <a className="dropdown-item d-none d-md-inline-block" href="/es">
              Spanish
            </a>
          </li>
          <li>
            <a className="dropdown-item d-md-none" href="/ar">
              AR
            </a>
            <a className="dropdown-item d-none d-md-inline-block" href="/ar">
              Arabic
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

export default LanguageSwitcher;
