import React from "react";

import Regulamento from "../../docs/regulamento-chama-snickers-21-02.pdf";

const FooterNav = () => {
  return (
    <nav>
      <ul className="nav justify-content-center text-uppercase">
        <li className="nav-link">
          <a
            href="https://www.mars.com/privacy-policy-portugal"
            target="_blank"
            rel="noopener noreferrer"
          >
            Política de Privacidade
            <span className="visually-hidden">opens in a new window</span>
          </a>
        </li>
        <li className="nav-link">
          <a
            href="https://www.mars.com/cookies-english"
            target="_blank"
            rel="noopener noreferrer"
          >
            Cookies
            <span className="visually-hidden">opens in a new window</span>
          </a>
        </li>
        <li className="nav-link">
          <a href={Regulamento} target="_blank" rel="noopener noreferrer">
            Regulamento
            <span className="visually-hidden">opens in a new window</span>
          </a>
        </li>
        <li className="nav-link">
          <a
            href="https://www.mars.com/legal-portugal"
            target="_blank"
            rel="noopener noreferrer"
          >
            Termos de Uso
            <span className="visually-hidden">opens in a new window</span>
          </a>
        </li>
        <li className="nav-link">
          <a
            href="https://www.mars.com/about/policies-and-practices/ca-supply-chain-transparency-act"
            target="_blank"
            rel="noopener noreferrer"
          >
            Lei de Transparência na Cadeia de Suprimentos da Califórnia
            <span className="visually-hidden">opens in a new window</span>
          </a>
        </li>
        <li className="nav-link">
          <a
            href="https://www.mars.com/accessibility"
            target="_blank"
            rel="noopener noreferrer"
          >
            Acessibilidade
            <span className="visually-hidden">opens in a new window</span>
          </a>
        </li>
        <li className="nav-link">
          <a
            href="https://www.mars.com/about/policies-and-practices/modern-slavery-act"
            target="_blank"
            rel="noopener noreferrer"
          >
            Lei Anti-escravidão Moderna
            <span className="visually-hidden">opens in a new window</span>
          </a>
        </li>
        <li className="nav-link">
          <a
            href="https://www.mars.com/mars-incorporated-adchoices-united-states"
            target="_blank"
            rel="noopener noreferrer"
          >
            AdChoices
            <span className="visually-hidden">opens in a new window</span>
          </a>
        </li>
        <li className="nav-link">
          <a
            href="https://www.mars.com/contact-us"
            target="_blank"
            rel="noopener noreferrer"
          >
            Fale conosco
            <span className="visually-hidden">opens in a new window</span>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default FooterNav;
