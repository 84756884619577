import * as React from "react";
import FooterNav from "./footerNavPt";
import Copyright from "./copyrightPt";

const Footer = () => (
  <footer className="main-footer">
    <div className="container">
      <FooterNav />
      <Copyright />
    </div>
  </footer>
);

export default Footer;
