import React, { useState, useEffect } from "react";
// import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby";

import AgeCalculator from "./agegate-component-pt";
import Footer from "./footer/footerPt";
import LanguageSwitcher from "./header/languageSwitcherPt";

import snickersLogo from "../images/snickers-hunger-insurance-pt.png";

const AgeGate = (props) => {
  const [isOldError, setIsOldError] = useState(false);
  const [ageError, setAgeError] = useState(false);
  useEffect(() => {
    document.querySelector("body").classList.add("agegate");
  }, []);
  return (
    <>
      <section className="agegate-hldr pt d-flex justify-content-between flex-column align-items-center pt-5">
        <div className="d-flex flex-column justify-content-center align-items-center h-100">
          <LanguageSwitcher />
          <Link to="/pt">
            <figure className="logo">
              {/* <StaticImage
                src="../images/snickers-hunger-insurance-pt.png"
                alt="Snickers Logo"
                quality={100}
              /> */}
              <img
                src={snickersLogo}
                alt="Snickers Logo"
                className="img-fluid"
              />
            </figure>
          </Link>
          {/* <h1 className="text-white text-uppercase">
            Informe sua idade para ter o seguro
          </h1> */}
          <h1 className="text-white text-uppercase">
            INFORME SUA IDADE PARA SEGUIR
          </h1>
          <AgeCalculator
            setIsOldError={setIsOldError}
            setAgeError={setAgeError}
            setAgeGateShow={props.setAgeGateShow}
          />
          {isOldError && (
            <p className="text-center input-error mb-0">
              {/* You are not Eligible to Enter into Site! */}
              Oop,s por favor complete os detalhes e aperte enter.
            </p>
          )}
          {ageError && (
            <p className="text-center input-error mb-0">
              {/* You are not Eligible to Enter into Site! */}
              Desculpe, sua entrada não é permitida neste site.
            </p>
          )}
        </div>
        <Footer />
      </section>
    </>
  );
};

export default AgeGate;
