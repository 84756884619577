import React from "react";

const Copyright = () => {
  return (
    <p className="text-center mb-0">
      Isso não é um “seguro” em nenhum sentido legal real. &copy;2022 Mars ou
      afiliados. Marcas comerciais de terceiros pertencem a seus respectivos
      proprietários.
    </p>
  );
};

export default Copyright;
